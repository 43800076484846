// Generated by Framer (013b13c)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-fJujh"

const variantClassNames = {R__hI8TvF: "framer-v-1vhu59s"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "R__hI8TvF", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1vhu59s", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"R__hI8TvF"} ref={refBinding} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 90, intrinsicWidth: 90, loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (((componentViewport?.height || 200) * 0.46500000000000025) - 45))), pixelHeight: 180, pixelWidth: 180, src: "https://framerusercontent.com/images/bL4acA1Ypt8xJrmDVsxSzgf4kXk.gif"}} className={"framer-119imd9"} data-framer-name={"Flame 1"} layoutDependency={layoutDependency} layoutId={"F49LdC07i"} transformTemplate={transformTemplate1}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fJujh.framer-12h45gi, .framer-fJujh .framer-12h45gi { display: block; }", ".framer-fJujh.framer-1vhu59s { height: 200px; position: relative; width: 200px; }", ".framer-fJujh .framer-119imd9 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 90px); left: 12px; overflow: visible; position: absolute; top: 47%; width: 90px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framernw9npmkw_: React.ComponentType<Props> = withCSS(Component, css, "framer-fJujh") as typeof Component;
export default Framernw9npmkw_;

Framernw9npmkw_.displayName = "flame_gif_source";

Framernw9npmkw_.defaultProps = {height: 200, width: 200};

addFonts(Framernw9npmkw_, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})